import { Badge, ChoiceList, DatePicker, LegacyStack, Text, TextField } from "@shopify/polaris";
import { Status } from "@shopify/polaris/build/ts/src/components/Badge";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { getSymbolFromCurrency } from "@smartrr/shared/utils/formatMoney";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import React, { useState } from "react";

import { DatePickerContainer } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import {
  CustomerAutocomplete,
  getSelectedDatesFromFilter,
  getTimeFromDate,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

import { TransactionHistoryCombinedKeyTypes } from "..";

export const ValueSelectorForTransactionHistoryFilter = ({
  field,
  value,
  setValue,
  currency = "USD",
  customerCurrency = "USD",
}: {
  field: string;
  value: string | string[] | undefined;
  setValue: (value: string | string[] | undefined) => void;
  currency: CurrencyCode | undefined;
  customerCurrency?: CurrencyCode;
}) => {
  const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });

  const handleMonthChange = (month: number, year: number) => setDate({ month, year });
  switch (field as TransactionHistoryCombinedKeyTypes) {
    case "createdDate":
    case "updatedDate": {
      return (
        <DatePickerContainer>
          <DatePicker
            allowRange
            multiMonth
            month={month}
            year={year}
            onChange={({ start, end }) => {
              const filter = [getTimeFromDate(start).toString()];
              if (end) {
                filter.push(getTimeFromDate(end).toString());
              }

              setValue(filter);
            }}
            onMonthChange={handleMonthChange}
            selected={getSelectedDatesFromFilter(value)}
          />
        </DatePickerContainer>
      );
    }
    case "status": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <ChoiceList
            title=""
            titleHidden
            allowMultiple
            selected={Array.isArray(value) ? value : value ? [value] : []}
            choices={[
              { label: "Succeeded", value: "succeeded" },
              { label: "Pending", value: "pending" },
              { label: "Processing", value: "processing" },
              { label: "Failed", value: "failed" },
              { label: "Canceled", value: "canceled" },
            ]}
            onChange={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    case "estimatedTotalNet": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(currency)}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }
    case "totalInCustomerCurrency": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(customerCurrency)}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }
    case "billAmount": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(currency)}
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }

    case "id": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="text"
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              setValue(value);
            }}
          />
        </LegacyStack>
      );
    }
    case "emailOrName": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <CustomerAutocomplete
            selected={Array.isArray(value) ? value : value ? [value] : []}
            handleCustomerChange={setValue}
            textFieldProps={{
              placeholder: "Search...",
            }}
          />
        </LegacyStack>
      );
    }
    case "retryCount": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="numeric"
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value >= 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }
    case "error": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            inputMode="text"
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              setValue(value);
            }}
          />
        </LegacyStack>
      );
    }
    default: {
      return (
        <LegacyStack vertical spacing="extraTight">
          <Text variant="bodyMd" as="span">
            Select a filter
          </Text>
        </LegacyStack>
      );
    }
  }
};

export const getBillCreatedDate = (createdDate: string, activeOrg: IOrganization | null) => {
  if (!createdDate || !activeOrg) {
    return "--";
  }

  const date = ISODateString.fromString(createdDate).setZone(activeOrg.billingTimezone);

  return `${date.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${date.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;
};

export const getLastUpdatedDateForBill = (updatedDate: string, activeOrg: IOrganization | null) => {
  if (!updatedDate || !activeOrg) {
    return "--";
  }

  const date = ISODateString.fromString(updatedDate).setZone(activeOrg.billingTimezone);

  return `${date.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${date.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;
};

export const getBillStatus = (bill: IBillWithPurchStAndCustomerRelationship, activeOrg: IOrganization | null) => {
  let badgeStatus: Status = "info";
  let text = "";
  const { status, retryCount, billingDate } = bill;

  if (status === "pending" && billingDate && retryCount > 0 && activeOrg) {
    const date = ISODateString.fromString(billingDate).setZone(activeOrg.billingTimezone);
    const duration = date.diffNow(["days", "hours", "minutes", "seconds"]);

    let durationFormatted = "";
    if (duration.days > 0) {
      durationFormatted = `${pluralize(duration.days, "day")}`;
    } else if (duration.hours > 0) {
      durationFormatted = `${pluralize(duration.hours, "hour")}`;
    } else if (duration.minutes > 0) {
      durationFormatted = `${pluralize(duration.minutes, "minute")}`;
    } else {
      durationFormatted = "a moment";
    }

    return <Badge status="warning">{`Retrying in ${durationFormatted}`}</Badge>;
  }

  switch (bill.status) {
    case "failed": {
      badgeStatus = "critical";
      text = "Failed";
      break;
    }
    case "pending": {
      badgeStatus = "new";
      text = "Processing";
      break;
    }
    case "processing": {
      badgeStatus = "new";
      text = "Processing";
      break;
    }
    case "succeeded": {
      badgeStatus = "success";
      text = "Succeeded";
      break;
    }
    case "canceled": {
      badgeStatus = "attention";
      text = "Canceled";
      break;
    }
  }

  return <Badge status={badgeStatus}>{text}</Badge>;
};
