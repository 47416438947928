import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { BigIntString } from "@smartrr/shared/entities/BigIntString";
import { IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

import { SearchDropdownOptions } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { TypedTableColumnType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { getOrderTotal } from "@vendor-app/app/AdminRoute/AdminOrdersRoute/libs";

import {
  TransactionHistoryTableColumnKeyType,
  getBillCreatedDate,
  getBillStatus,
  getLastUpdatedDateForBill,
} from "..";
import { TransactionHistoryParsedDataType } from "../types";

export const billSearchOptions: SearchDropdownOptions = [
  { label: "Email/Name", value: "emailOrName", placeholder: "Search by email or name" },
];

export const transactionHistoryColumns: TypedTableColumnType<TransactionHistoryTableColumnKeyType> = {
  id: {
    name: "Transaction ID",
    paginatedValue: "id",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  createdDate: {
    name: "Initial bill attempt",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  updatedDate: {
    name: "Last update time",
    paginatedValue: "updatedDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Customer",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  billAmount: {
    name: "Total",
    paginatedValue: "billAmount",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  retryCount: {
    name: "Retry count",
    paginatedValue: "retryCount",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  error: {
    name: "Failure reason",
    paginatedValue: "error",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

export const parseBillsForIndexTable = (
  bills: IBillWithPurchStAndCustomerRelationship[],
  activeOrg: IOrganization | null
): TransactionHistoryParsedDataType[] => {
  return bills.map(bill => ({
    id: String(bill.id),
    uniqueId: bill.uniqueId,
    createdDate: getBillCreatedDate(bill.createdDate, activeOrg),
    updatedDate: getLastUpdatedDateForBill(bill.updatedDate, activeOrg),
    emailOrName: `${bill.st.custRel?.firstName} ${bill.st.custRel?.lastName}`,
    billAmount: formatMoney(BigIntString.fromBigIntString(bill.billAmount), bill.billCurrency as CurrencyCode),
    estimatedTotalNet: bill.order
      ? getOrderTotal(
          bill.order.shopIncome,
          bill.order.totalRefund,
          bill.order.orderStatus,
          activeOrg?.shopifyStoreData?.currency
        )
      : "——",
    status: getBillStatus(bill, activeOrg),
    retryCount: bill.retryCount,
    error: bill.error ?? "——",
    orderId: bill.order?.shopifyOrderLabel ?? "——",
    totalInCustomerCurrency: activeOrg?.shopifyStoreData?.currency
      ? formatMoney(BigIntString.fromBigIntString(bill.billAmount), bill.billCurrency as CurrencyCode)
      : "——",
  }));
};
