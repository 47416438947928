import { IBillWithPurchStAndCustomerRelationship } from "@smartrr/shared/entities/Billing";

export const checkIfBillCanBeRetried = (
  bills: IBillWithPurchStAndCustomerRelationship[],
  selectedResources: string[]
) => {
  return (
    bills.findIndex(
      bill =>
        selectedResources.includes(String(bill.id)) &&
        (bill.status === "canceled" ||
          bill.status === "processing" ||
          bill.status === "succeeded" ||
          bill.st.purchaseStateStatus !== "ACTIVE")
    ) == -1
  );
};
